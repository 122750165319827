import { BaseEntity } from './BaseEntity';

export enum BannerPosition {
  HOME = 'home',
  PLANS = 'plans',
  DEALS = 'deals',
  ABOUT = 'about',
  COLLABORATION = 'collaboration',
  CONTACT_US = 'contact_us',
  MODELS = 'models',
  NEWS = 'news',
  SITES = 'sites',
  VIDEOS = 'videos',
  INFORMATION = 'information',
  SEARCH_RESULT = 'search_result',
  COMPLAINTS_POLICY= 'complaints_policy',
  USER_AREA= 'user_area'
}

export enum BannerType {
  IMAGE = 'image',
  VIDEO = 'video',
  TIMER = 'timer'
}

export enum BannerCta {
  YES = 1,
  NO = 0
}

export enum BannerImageRatio{
  DEFAULT = 192 / 79,
  PLANS_RATIO = 192 / 19
}

export enum BannerMobileImageRatio{
  DEFAULT = 75 / 62,
  PLANS_RATIO = 192 / 19
}
export interface Banner extends BaseEntity {
  name: string
  nameCn: string
  title: string
  titleCn: string
  subtitle: string
  type: BannerType
  video: string
  mobileVideo: string
  videoLink: string
  image: string
  imageLink: string
  mobileImage: string
  mobileImageLink: string
  link: string
  sort: number
  cta: string
  position: BannerPosition
  clicks: number
  expiredAt: string
  isCta: BannerCta
}

export type PartialBanner = Partial<Banner>;
